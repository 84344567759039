/*
 * 업무구분 : 업무공통
 * 화면 명 : MSPBC003M
 * 화면 설명 : 오늘의 활동(FC홈)
 */
<template>
  <ur-page-container title="오늘의 활동" class="msp home_new todayActivityPage" :show-title="true" type="page" :action-type="actionSlot" :customer="fcData.label" :customerID="fcData.key" ref="activity">
    <div class="msp h100">
      <div class="bottom_open_float">
        <!-- 추가 2024.12.20 :chan -->
        <div class="todayActivityMainImgWrap">
          <div>
              <span>{{date}}</span>
              <span class="day">{{day}}</span>
          </div>
          <div class="cheerUp" v-if="holiday != ''">{{holiday}}</div>
          <div class="cheerUp" v-else>오늘 하루도 응원해요!</div>
        </div>
        <div class="activityScrollBtnWrap">
          <mo-button class="activityScrollBtn checked" @click="scrollButtonCheck(0, true)">내 일정 {{SchdList.length > 0 ? SchdList.length : ''}}</mo-button>
          <mo-button class="activityScrollBtn"         @click="scrollButtonCheck(1, true)">활동캘린더 {{EvtCust_TotCnt > 0 ? EvtCust_TotCnt : ''}}</mo-button>
          <mo-button class="activityScrollBtn"         @click="scrollButtonCheck(2, true)">동의만료 고객 {{DeltCust_TotCnt > 0 ? DeltCust_TotCnt : ''}}</mo-button>
          <mo-button class="activityScrollBtn"         @click="scrollButtonCheck(3, true)">전화활동 {{callList.length > 0 ? callList.length : ''}}</mo-button>
          <mo-button class="activityScrollBtn"         @click="scrollButtonCheck(4, true)">활동골든룰 {{ scrollBtn_AcvmtLabel }}</mo-button>
          <mo-button class="activityScrollBtn"         @click="scrollButtonCheck(5, true)">이달의 업적</mo-button>
        </div>
        <!-- 추가 끝 2024.12.20 :chan -->
        <div class="overflow-scroll-y overflow-hidden-x mt20 pl24 fexTy1">
          <div class="myWork_container">
            <!-- 내 일정 --><!-- 수정 2024.12.20 :chan -->
            <ur-box-container alignV="start" componentid="" direction="column" class="schedule-item mt20 scrollTo">
              <div class="info--tit flex" @click="$router.push({name: 'MSPAP314M', params: {pScrId:'MSPBC003M', pData: fcData}})">
                <span class="fs18rem fwb myActivitySubject">내 일정</span> <mo-icon icon="msp-arrow-pre" class="deg--180 ml10">msp-arrow-pre</mo-icon>
              </div>
              <div class="myActTip mb0" v-if="SchdList.length > 0"  >일정을 확인해보세요</div>
              <div class="myActTip mb0" v-if="SchdList.length === 0">일정을 등록해보세요</div>

              <mo-list :list-data="SchdList" v-if="SchdList.length > 0" class="myScheduleListWrap">
                <template #list-item="{item, index}" style="display:flex">
                  <div class="myScheduleWrap" v-if="index < scheduleAmount">
                    <div class="myScheduleTimeWrap">
                      <div class="myScheduleAmpm">{{item.afternoonYn}}</div>
                      <div class="myScheduleTime actTimeColor1">{{item.startTime}}</div>
                    </div>
                    <div class="myScheduleBox" :class="{'actBoxColor1':item.fcSchdlScCd==='2', 'actBoxColor2':item.fcSchdlScCd==='1', 'actBoxColor3':item.fcSchdlScCd==='3', 'actBoxColor4':item.fcSchdlScCd==='4', 'actBoxColor5':item.fcSchdlScCd==='5'}">
                      <!-- 수정 2025.3.13 :chan -->
                      <div class="myScheduleIconWrap" @click="fn_OpenCustInfoBs(item)">
                        <span>{{item.custNm}}</span>
                        <span class="myScheduleIntention" :class="[{'skyblue':item.fcSchdlScCd==='1'},{'blue':item.fcSchdlScCd==='2'},{'yellow':item.fcSchdlScCd==='3'},{'green':item.fcSchdlScCd==='5'}]" v-if="item.CntcNm">
                          {{item.CntcNm}}
                        </span>
                      </div>
                      <div v-show="item.fcSchdlPrmsPlcNm" class="myScheduleIconAddress">{{item.fcSchdlPrmsPlcNm}}</div>
                      <div v-show="item.SchdlNm" class="myScheduleMemo innerBox">{{item.SchdlNm}}</div>
                      <div v-show="item.sharePmtImgNm" class="myScheduleAttachment" :class="item.isIn3Month?'isIn3Month' : ''" @click="fn_LoadImg(item)">
                        <mo-icon icon="clip" class="fs16rem"/>
                        <span>차트보기</span>
                      </div>
                      <!-- 수정 끝 2025.3.13 :chan -->
                    </div>
                  </div>
                  <div v-if="SchdList.length > scheduleList && index===scheduleAmount-1" class="showMoreScheduleWrap" @click="showAllSchedule()">
                    <mo-icon class="showMoreScheduleIcon" :class="{'up':showMore==='줄이기'}">msp-arrow-down</mo-icon>{{showMore}}
                  </div>
                </template>
              </mo-list>
              <!-- schedule이 없을 경우 표시 -->

              <div class="myScheduleNoneWrap" v-if="SchdList.length===0">
                <div class="myScheduleNone"><img src="@/assets/img/icon/icon_calendar.png"></div>
                <mo-button class="btnAddMySchedule" v-if="mngSearch" @click="fn_schlAdd()">일정추가</mo-button>
              </div>
              
              <!-- 차트보기 -->
              <mo-bottom-sheet ref="bottom_sheet_1" :close-btn="false"  class="ns-bottom-sheet closebtn">
                <template v-slot:title>
                  차트보기
                  <div class="ns-btn-close" @click="fn_CloseSheet1()" name="닫기"></div>
                </template>
                <div class="content-area pb30">
                  <ul class="bullet-type-3 crTy-bk7 fs14rem mb10">
                    <li>이미지 터치 시 확대할 수 있습니다.</li>
                  </ul>
                  

                  <!-- 슬라이딩 배너 영역 --> 
                  <MSPCarousel ref="container1" class="w100 h100 mt10" :number-of-page="lv_ImgList.length" width="auto" height="auto" :swiper-option="swiperOption" :prevButton="false" :nextButton="false" noRotate
                                @on-click="fn_ImgClick">
                    <template #1>
                      <div>
                        <img v-bind:src="lv_ImgList[0]"/>
                      </div>
                    </template>
                    <template #2>
                        <div>
                          <img v-bind:src="lv_ImgList[1]"/>
                        </div>
                    </template>
                  </MSPCarousel>
                  <!-- 슬라이딩 배너 영역 //--> 
                  
                </div>

                <template v-slot:action>
                  <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
                    <div class="relative-div">
                      <mo-button componentid="" color="primary" shape="primary" size="border" @click="fn_CloseSheet1()" class="ns-btn-round blue">확인</mo-button>
                    </div>
                  </ur-box-container>
                </template>
              </mo-bottom-sheet>
              <!-- Popup002 end -->


            </ur-box-container>
            <!-- 내 일정 끝 -->

            <!-- 활동 캘린더 --><!-- 부분 수정 2024.12.20 :chan -->
            <ur-box-container alignV="start" componentid="" direction="column" class="mt40 scrollTo">
              <div class="myActivityBox">
                <div class="flex myActivitySubjectWrap" @click="$router.push({name: 'MSPAP320M', params: {pScrId:'MSPBC003M', pData: fcData}})">
                  <span class="fs18rem fwb myActivitySubject">활동캘린더</span> <mo-icon icon="msp-arrow-pre" class="deg--180 ml10">msp-arrow-pre</mo-icon>
                </div>
                <div class="myActTip" v-if="EvtCust_TotCnt > 0">특별한날을 맞이한 고객에게 연락해보세요</div>
                <div class="myActTip" v-if="EvtCust_TotCnt===0">특별한날을 맞이한 고객이 없어요</div>
                <mo-list :list-data="evtCustList" v-if="EvtCust_TotCnt > 0" class="myActListBox">
                  <template #list-item="{item}">
                    <div v-if="item.cnt > 0" class="whatslist" @click="$router.push({name: 'MSPAP320M', params: {pScrId:'MSPBC003M', pData: fcData, evtTypNm: item.evtTypNm}})">
                      <div class="whatsdate">
                        <div class="myActIcon" :class="[{'iconBirthday':item.evtTypNm==='생일'},{'iconCake':item.evtTypNm==='계약1주년'}, {'iconCalendar':item.evtTypNm==='상령일'}, {'iconHeart':item.evtTypNm==='만기일'}]"></div>
                        <span>{{item.evtTypNm}}</span><span class="actionNum">{{item.cnt}}</span>
                      </div>
                      <div class="actionMembers">
                        <!-- <span v-for="key in item.custNm" :style="getStyle(key.length)">{{key.name}}</span> -->
                        <span v-for="(data,i) in item.custNm" :key="i" :style="getStyle(data.length)">{{data.name}}</span>
                      </div>
                    </div>
                  </template>
                </mo-list>
                <div class="myActEmptyData" v-if="EvtCust_TotCnt===0">
                  <div class="myActEmptyDataIcon"><mo-icon>iconPerson</mo-icon></div>
                </div>
              </div>
            </ur-box-container>
            <!-- 활동 캘린더 끝 -->

            <!-- 동의만료 고객 --><!-- 동의만료 고객 --><!-- 신규 2024.12.20 :chan -->
            <ur-box-container alignV="start" componentid="" direction="column" class="mt40 scrollTo">
              <div class="myActivityBox">
                <div class="flex myActivitySubjectWrap" @click="$router.push({name: 'MSPCM217M', params: {pScrId:'MSPBC003M', pData: fcData}, query: {t: new Date().getTime()}})">
                  <span class="fs18rem fwb myActivitySubject">동의만료 고객</span> <mo-icon icon="msp-arrow-pre" class="deg--180 ml10">msp-arrow-pre</mo-icon>
                </div>

                <div class="myActTip" v-if="!is_DeltCustEmpty">동의가 만료되는 고객을 터치해보세요</div>
                <div class="myActTip" v-else>오늘 동의가 만료되는 고객이 없어요</div>
                <div class="myActListBox" v-if="!is_DeltCustEmpty">
                  <div class="myActivitySubjectItem">
                    <img class="myActivitySubjectIcon" src="@/assets/img/icon/icon_doc.png" />
                    <span>필컨만료 예정</span>
                    <span class="myActivitySubjectNumber">{{DeltCust_TotCnt}}</span>
                  </div>
                  <div v-for="(item, index) in deltCustData" :key="index" class="myActivityGrid">
                    <div class="myActivityBadge" :class="{'red': item.evtTypNm==='D-day'}" style="width:58px;">{{item.evtTypNm}}</div>
                    <div class="myActivityMember">{{item.custNmList}}</div>
                  </div>
                </div>
                <div class="myActEmptyData" v-if="is_DeltCustEmpty">
                  <div class="myActEmptyDataIcon"><mo-icon>iconPerson</mo-icon></div>
                </div>
              </div>
            </ur-box-container>
            <!-- 동의만료 고객 끝 -->

            <!-- 전화활동 --><!-- 신규 2024.12.20 :chan -->
            <ur-box-container alignV="start" componentid="" direction="column" class="mt20 scrollTo">
              <div class="myActivityBox">
                <div class="flex myActivitySubjectWrap" @click="$router.push({name: 'MSPAP310M', params: {pScrId:'MSPBC003M', pData: fcData}})">
                  <span class="fs18rem fwb myActivitySubject">전화활동</span> <mo-icon icon="msp-arrow-pre" class="deg--180 ml10">msp-arrow-pre</mo-icon>
                </div>
                <div class="myActTip" v-if="callList.length > 0">오늘 {{callList.length}}건의 전화활동을 했어요 (총 {{callCnt.length}}명)</div>
                <div class="myActTip" v-if="callList.length===0">고객과의 통화로 하루를 시작해보세요</div>
                <div class="myActCallListBox" v-if="callList.length > 0">
                  <div v-for="(item, index) in callList" :key="index">
                    <div class="myActCallListItem" :class="{'mini':callList.length <= callList && index === callList.length-1}" v-if="index < callListAmount" @click="$router.push({name: 'MSPAP310M', params: {pScrId:'MSPBC003M', pData: fcData}})">
                      <div class="myActCallListIcon" v-if="item.mobslCustCntcId==='HC08' || item.mobslCustCntcId==='HC10' ||item.mobslCustCntcId==='SEND'">
                        <mo-icon>iconCall</mo-icon>
                        <span>발신</span>
                      </div>
                      <div class="myActCallListIcon" v-if="item.mobslCustCntcId==='HC11' || item.mobslCustCntcId==='RECI'">
                        <mo-icon>iconReceive</mo-icon>
                        <span class="blue">수신</span>
                      </div>
                      <div>
                        <div class="name">{{item.custNm}}</div>
                        <div class="time">{{item.mobslCntcCntnt}}</div>
                        <div class="memo" v-html="item.phclMemoCntnt"></div>
                      </div>
                    </div>
                    <div v-if="callList.length > todayCallList && index===callListAmount-1" class="showMoreScheduleWrap" @click="showAllCallList()">
                      <mo-icon class="showMoreScheduleIcon" :class="{'up':showAllList==='줄이기'}">msp-arrow-down</mo-icon>{{showAllList}}
                    </div>
                  </div>
                </div>
                <div class="myActEmptyData" v-if="callList.length===0">
                  <div class="myActEmptyDataIcon"><mo-icon>iconPerson</mo-icon></div>
                </div>
              </div>
            </ur-box-container>
            <!-- 전화활동 끝 -->

            <!-- 활동 골든룰 --><!-- 신규 2024.12.20 :chan -->
            <ur-box-container alignV="start" componentid="" direction="column" class="mt40 scrollTo">
              <div class="myActivityBox">
                <div class="flex myActivitySubjectWrap" @click="$router.push({name: 'MSPAP308M', params:{prePageId:'MSPBC003M', pData: fcData}})">
                  <span class="fs18rem fwb myActivitySubject">활동골든룰</span> <mo-icon icon="msp-arrow-pre" class="deg--180 ml10">msp-arrow-pre</mo-icon>
                </div>
                <div class="myActTip icon" v-if="GldnrChk==='달성'">  {{GldnrWekl}} 달성 축하드려요!</div>
                <div class="myActTip"      v-if="GldnrChk==='진행중'">{{GldnrWekl}} 달성에 도전중이에요</div>
                <div class="myActTip"      v-if="GldnrChk==='미진행'">{{GldnrWekl}} 활동골든룰 미진행</div>

                <div class="myActGoldenRuleWrap" :class="[{'case1':iCGldnrActWeklySmupDtlVO.length===1}, {'case3':iCGldnrActWeklySmupDtlVO.length%3===0}]">
                  <div v-for="(item, index) in iCGldnrActWeklySmupDtlVO" :key="index">
                    <div class="myActGoldenRuleBox" :class="[{'case3':iCGldnrActWeklySmupDtlVO.length%3===0}, {'incomplete':item.actCnt < item.gldnrActTypGoalVal01}]" @click="$router.push({name: 'MSPAP308M', params:{prePageId:'MSPBC003M', pData: fcData}})">
                      <div class="subject">{{item.gldnrActTypNm}}</div>
                      <div class="dataBox"><span class="data1">{{item.actCnt}}</span>&nbsp;<span class="data2">{{item.gldnrActTypGoalVal01}}</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </ur-box-container>
            <!-- 활동 골든룰 끝 -->

            <!-- 이달의 업적 --><!-- 수정 2024.12.20 :chan -->
            <ur-box-container id="myAchievWrap" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list mt30 scrollTo">
              <template>
                <component :is="'style'">
                  .moveFlagWrapAdd {animation-name:transCharacter}
                  .percentBar {animation-name:percentBarAni}
                  .percentNumberWrapAdd {animation-name:percentNumberWrapAni}
                  .moveFlagWrapAdd, .percentBar, .percentNumberWrapAdd
                  {animation-duration:1s; animation-iteration-count:1; animation-fill-mode:forwards; animation-delay:0.5s}
                  @keyframes transCharacter {
                    0% {width:0px} 100% {width:{{flagMoveValue}}}
                  }
                  @keyframes percentBarAni {
                    0% {width:0px} 100% {width:{{zero100AchievValue}}%}
                  }
                  @keyframes percentNumberWrapAni {
                    0% {width:20px} 100% {width:{{percentNumberMoveValue}}}
                  }
                </component>
              </template>
              <div class="flex myActivitySubjectWrap" @click="$router.push({name: 'MSPAP100M', params: {pScrId:'MSPBC003M', pData: fcData}})">
                <span class="fs18rem fwb myActivitySubject">이달의 업적</span> <mo-icon icon="msp-arrow-pre" class="deg--180 ml10">msp-arrow-pre</mo-icon>
              </div>
              <div class="myActTip icon" v-if="achievValue === 100">정예화 달성 축하드려요!</div>
              <div class="myActTip" v-else>{{ fn_GetRateMsg.txt1 }}&nbsp;{{ fn_GetRateMsg.txt2 }}</div>
              <div class="activityAchievBg" @click="$router.push({name: 'MSPAP100M', params: {pScrId:'MSPBC003M', pData: fcData}})">
                <div class="activityAchievBarWrap">
                  <div class="moveFlagWrap" :class="[{'moveFlagWrapAdd':runFlagSwitch}]">
                    <img src="@/assets/img/icon/icon_flag.png" alt="flag" class="activityAchievFlagImg">
                  </div>
                  <div class="activityAchievBarTrack"></div>
                  <div class="activityAchievBar" :class="[{'percentBar':runFlagSwitch}]"></div>
                  <div class="percentNumberWrap" :class="[{'percentNumberWrapAdd':runFlagSwitch}]">
                    <div class="percentNumberBox"><span class="percentNumber">{{this.achievValueCounter}}</span><span class="percentChar">%</span></div>
                  </div>
                </div>
                <div class="activityAchievImage">
                  <div class="myAchievClubBadge">{{$bizUtil.replaceAll(lv_ChnlQulfNm, '만클럽', '만 Club')}}</div>
                </div>
              </div>
            </ur-box-container>
            <!-- 이달의 업적 끝 -->
            <div class="mt80"></div>
          </div>
        </div>

        <!-- 크게보기 모드 -->
        <mo-bottom-sheet ref="bottom_sheet_2" class="ns-bottom-sheet ptmode" >
          <mo-top-bar>
            <mo-button class="btn_popup_back"></mo-button>
            <div slot="nav" class="icon-wrapper" @click="fn_CloseSheet2()">
              <mo-icon color="white" borderless>drawer</mo-icon>
            </div>
            <div class="ui-gnb-title__main">인별현황</div>
          </mo-top-bar>
          <!-- content 영역 -->
          <div class="content-area">
            <ur-box-container alignV="start" direction="column" class="ns-list-area">
              <ur-box-container>
                <div class="sticky-area">
                  <div class="table-area sticky-t08" ref="tableAreaPop" @scroll="fn_checkScroll()"><!-- @scroll="fn_checkScroll(2)" -->
                    <table class="table row-type ws_N">
                      <thead>
                        <tr>
                          <th></th>
                          <th>접속여부</th>
                          <th>등록일정</th>
                          <th>전화</th>
                          <th>활동골든룰</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in lv_FcActInfoListSVO" :key="i">
                          <td>{{item.cnsltNm}}({{item.cnsltNo}})</td>
                          <td class="aR">{{item.conctYn}}</td>
                          <td class="aR">{{item.schdlCnt}}</td>
                          <td class="aR">{{item.callCnt}}</td>
                          <td class="aR">{{item.acvmtLabel}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <button class="icon-right" @click="fn_scrollToRight()" v-if="isShowChevron" ><i></i></button>
                  <button class="icon-down" @click="fn_scrollToDown()" v-if="isShowChevronDown" ><i></i></button>
                </div>
              </ur-box-container>
            </ur-box-container>
          </div>
        </mo-bottom-sheet>
        <!-- BottomSheet end -->


      </div>
    </div>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역                                                     *
 ***********************************************************************************/
import MenuConst from '@/ui/sp/MenuConstans.js'
import SpConstants from '@/config/constants/spConstants'
import apConstants from '@/config/constants/apConstants'
import moment from 'moment'
import DateUtil from '@/ui/sp/common/exDateUtils'
import MSPSA203P from '@/ui/sa/MSPSA203P.vue'
import MSPSA210P from '@/ui/sa/MSPSA210P.vue'
import MSPSA222P from '@/ui/sa/MSPSA222P.vue'
import MSPSA063P from '@/ui/sa/MSPSA063P.vue'
import MSPCM128P from '@/ui/cm/MSPCM128P.vue'
import MSPCM152P from '@/ui/cm/MSPCM152P.vue'
import MSPCM154P from '@/ui/cm/MSPCM154P.vue'
import MSPAP106P from '@/ui/ap/MSPAP106P'
import MSPAP302D from '@/ui/ap/MSPAP302D' // FC 일정추가
import MSPAP303P from '@/ui/ap/MSPAP303P' // 관리자 일정추가
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import MSPCarousel from '@/ui/_cmm/components/MSPCarousel'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역                                                           *
   ***********************************************************************************/
  name: 'MSPBC003M',
  screenId: 'MSPBC003M',
  components: {
    MSPCarousel
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역                                                         *
   ***********************************************************************************/
  created() {
    let lv_vm = this
    lv_vm.getStore('bcStore').dispatch('SPEAKER_YN', true)
    lv_vm.getStore('bcStore').dispatch('FC_ACT_INFO_BTN_SHOW', true)
    // 조회권한 확인
    let template =''
    if (this.getStore('userInfo').getters.getUserInfo.isSrchOrgCombo === 'Y') {
      template = "<mo-icon @click='clickSearch'>search</mo-icon>"
    } else {
      template = "<mo-icon></mo-icon>"
    }

    // 상단 검색, 카테고리 버튼 생성
    this.actionSlot = {
      actionComponent: {
        template: template,
        methods: {
          clickSearch() {
            lv_vm.fn_ManagerSearch()
          }
        }
      }
    }
  },
  mounted() {
    document.querySelector('.todayActivityPage').addEventListener('scroll', this.catchScrollEvent, true)
    document.querySelector('.activityAchievBar').addEventListener('animationend', this.achievValueAniEnd, true)
    window.addEventListener('resize', this.catchScrollEvent, true)
    // 추가 2025.1.10 :chan
    document.querySelector('.activityScrollBtnWrap').addEventListener('touchstart', this.catchTouchEvent, true)
    document.querySelector('.activityScrollBtnWrap').addEventListener('touchend', this.catchTouchEvent, true)
    document.querySelector('.activityScrollBtnWrap').addEventListener('scrollend', this.catchTouchEvent, true)
    // 추가 끝 2025.1.10 :chan
  },
  beforeUnmount() {
    document.querySelector('.todayActivityPage').removeEventListener('scroll', this.catchScrollEvent, true)
    document.querySelector('.activityAchievBar').removeEventListener('animationend', this.achievValueAniEnd, true)
    window.removeEventListener('resize', this.catchScrollEvent, true)
    // 추가 2025.1.10 :chan
    document.querySelector('.activityScrollBtnWrap').removeEventListener('touchstart', this.catchTouchEvent, true)
    document.querySelector('.activityScrollBtnWrap').removeEventListener('touchend', this.catchTouchEvent, true)
    document.querySelector('.activityScrollBtnWrap').removeEventListener('scrollend', this.catchTouchEvent, true)
    // 추가 끝 2025.1.10 :chan
  },
  activated() {  
    // 휴일목록 조회
    this.fn_SelectHolidayList()

    // 변수 초기화
    this.fn_Init()
    
    // 지점장 여부 확인
    if (this.getStore('userInfo').getters.getUserInfo.userRoleList.indexOf('F110') > -1) {
      this.isManager = true

      let cnslt = this.getStore('bcStore').getters.getState.fcData
      if(Object.keys(cnslt).length !== 0) {
        this.fcData  = cnslt
        this.cnsltNo = cnslt.key

        this.getStore('bcStore').dispatch('FC_DATA_DEL') 

        this.fn_searhData()
      } else {
        this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: true,
            title: "",
            content: '컨설턴트를 먼저 선택해 주세요.',
            title_pos_btn: "확인"
          },
          listeners: {
            onPopupConfirm: () => {
              console.log('onPopupConfirm call')
              this.$bottomModal.close(this.lv_AlertPop)
              this.fn_ManagerSearch()
            },
            onPopupCancel: () => {
              console.log('onPopupCancel call')
              this.$bottomModal.close(this.lv_AlertPop)
              this.fn_ManagerSearch()
            },
            onPopupClose: () => {
              console.log('onPopupClose call')
              this.$bottomModal.close(this.lv_AlertPop)
              this.fn_ManagerSearch()
            }
          }
        })
      }
    } else {
      this.isManager = false

      this.cnsltNo = this.getStore('userInfo').getters.getUserInfo.userId

      this.fn_searhData()
    }

    // 오늘 날짜 설정
    let ymd = new Date()
    this.date = moment(new Date()).format('M월D일')

    switch (ymd.getDay()) {
      case 0:
        this.day = '일';
        break;
      case 1:
        this.day = '월';
        break;
      case 2:
        this.day = '화';
        break;
      case 3:
        this.day = '수';
        break;
      case 4:
        this.day =  '목';
        break;
      case 5:
        this.day = '금';
        break; 
      default:
        this.day = '토';
        break;
    }
  },
  deactivated() {
    // scroll 위치 저장
    this.beforeScrollTop = this.$refs.activity.beforeScroll

    this.getStore('bcStore').dispatch('FC_ACT_INFO_BTN_SHOW', false)
  },
  deactivated() {
    this.getStore('bcStore').dispatch('FC_ACT_INFO_BTN_SHOW', false)
  },
  /***********************************************************************************
   * 화면변수 선언 영역                                                              *
   ***********************************************************************************/
  data() {
    this.MenuConst = MenuConst
    return {
      lv_ImgList: [],
      lv_GldnrActList: [],

      isShowChevron: false,
      isShowChevronDown: false,

      swiperOption: {
        // centerSlides: true,
        slidesPerView: 1,
        spaceBetween: 20,
        autoplay: false,
        /* autoplay: {
          delay: 5000,
          disableOnInteraction: false, 
        }, */
      },

      //2024-12-30 오늘의활동 개선
      fcData         : {},          // 현재 조회중인 사번
      fcDtlData      : {},          // 현재 조회중인 사번 조직번호
      smryText       : '',          // 활동 요약 text
      scheduleList   : 3,           // '내일정' 보여지는 list 기본 상수
      scheduleAmount : 3,           // '내일정' 보여지는 list 기본 변수
      todayCallList  : 3,           // '전화활동' 보여지는 list 기본 상수
      callListAmount : 3,           // '전화활동' 보여지는 list 기본 변수
      showMore       : '더보기',
      showAllList    : '더보기',
      showAllIcon    : 'plus',
      evtCustList    : [],
      isManager      : false, // 관리자 여부
      cnsltNm        : '',
      cnsltNo        : '',
      callList       : '',
      callCnt        : [],           // 총 전화 인원
      SchdList       : [],
      date           : '',
      day            : '',

      lv_ChnlQulfNm: '',
      lv_IsShowGauge: !['BK','DI','DS','FS','GA','JK'].includes(this.getStore('userInfo').getters.getUserInfo.srvcMngScCd),
      lv_GaugeCount: 0,
      lv_APThmmActActulSVO: {},
      lv_ActualRate: 0,
      cMAllCustCntSVO: {
        cm00: 0,
        cm01: 0,
        cm02: 0,
        cm03: 0,
        cm04: 0,
        cm05: 0,
        cmTot: 0,
      },
      
      lv_ICDeltParngCust: [], // 동의만료고객(new)
      lv_DeltCustList: [[],[],[],[]],
      deltCustData:[],

      lv_gldnrActrExist: false,   //골든룰 유저 확인

      holiday : '', // 휴일

      iCGldnrActWeklySmupDtlVO:[],
      GldnrChk  : '',
      GldnrWekly: '',

      // 이달의 업적
      achievValue                   : 20, // [이달의 업적] character animation 화면 표시 정수값(0% ~ 100%), 소수일 경우 버림으로 표시됨
      percentNumberMoveValue        : '0',
      activityMessageTransformValue : '0',
      achievValueCounter            : 0,
      achievValueTimeout            : null,
      achievValueIntervalTimer      : null,
      achievValueTimerSwitch        : false,
      runFlagSwitch                 : false,
      startFlagSwitch               : false,
      flagMoveValue                 : '0',
      currentScrollToIndex          : 0,
      scrollBtnLeftStart            : 0, // 2025.1.10
      scrollBtnLeftEnd              : 0, // 2025.1.10
      
      //달성,미달성,진행중,예정
      lv_acvmtYnData: [
        {key: '1', label: '달성'},
        {key: '2', label: '미달성'},
        {key: '3', label: '진행중'},
        {key: '4', label: '예정'},
        {key: '5', label: '미진행'}
      ],
      lv_AcvmtItmData: [
        { key: '01', label: '조회' },
        { key: '02', label: '귀점' },
        { key: '03', label: '귀점후활동' },
        { key: '04', label: '필컨동의' },
        { key: '05', label: '신규필컨' },
        { key: '06', label: '통합보장설계' },
        { key: '07', label: '가입설계' },
        { key: '08', label: '청약(발행)' },
        { key: '09', label: '신계약' },
        { key: '10', label: '컨설팅' },
        { key: '11', label: '전화' },
        { key: '12', label: '문자' },
        { key: '13', label: '이메일' },
        { key: '14', label: '조회&귀점' },
        { key: '15', label: '사업자등록증' }
      ],
      lv_FcActInfoListSVO: [] // FC 인별현황 리스트
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    fn_GetUserInfo() {
      return this.getStore('userInfo').getters.getUserInfo
    },
    fn_GetRateRange() {
      const rate = this.lv_ActualRate
      return rate >= 100 ? '100'
           : rate >= 80  ? '80'
           : rate >= 60  ? '60'
           : rate >= 40  ? '40'
           : rate >= 20  ? '20'
           : '00'
    },
    fn_GetRateMsg() {
      const rate = this.lv_ActualRate
      return rate >= 100 ? {txt1:'정예화달성', txt2:'축하드려요!!'}
           : rate >= 80  ? {txt1:'정예화달성', txt2:'거의 다 왔어요'}
           : rate >= 60  ? {txt1:'정예화달성', txt2:'잘 하고 있어요'}
           : rate >= 40  ? {txt1:'정예화달성', txt2:'조금만 더 힘내세요~'}
           : rate >= 20  ? {txt1:'정예화달성', txt2:'도전 차근차근 가볼까요?'}
           : {txt1:'정예화달성을 위해', txt2:' 도전 시작!'}
    },
    zero100AchievValue() {
      let returnValue = 0
      if (this.lv_ActualRate < 0) returnValue = 0
      else if (this.lv_ActualRate > 100) returnValue = 100
      else if (this.lv_ActualRate >= 0 && this.lv_ActualRate <= 100) returnValue = Math.floor(this.lv_ActualRate)
      return returnValue
    },
    documentHeight() {
      return document.querySelector('.todayActivityPage').clientHeight
    },
    documentScale() {
      // 수정 2025.1.10 :chan
      return {
        width: document.querySelector('.todayActivityPage').clientWidth,
        height: document.querySelector('.todayActivityPage').clientHeight
      }
    },
    mngSearch () {
      if(this.cnsltNo != this.getStore('userInfo').getters.getUserInfo.cnsltNo) {
        return false
      } else {
        return true
      }
    },
    is_DeltCustEmpty() {
      let rtnValu = (this.lv_DeltCustList[0].length === 0 &  // D-day
                      this.lv_DeltCustList[1].length === 0 & // D-1
                      this.lv_DeltCustList[2].length === 0 & // D-2
                      this.lv_DeltCustList[3].length === 0 ) // D-3
      return rtnValu
    },
    DeltCust_TotCnt() {
      let deltCustList = this.lv_DeltCustList
      let deltCustTotCnt = 0
        deltCustList.forEach(item => {
          if (item.evtTypNm !== '필컨만료' && !this.$bizUtil.isEmpty(item.totCnt)) {
            deltCustTotCnt += item.totCnt
          }
        })

      return deltCustTotCnt
    },
    scrollBtn_AcvmtLabel() {
      let acvmtLabel = ' ' // defualt 빈값

      if (!_.isEmpty(this.lv_GldnrActList)) {
        let curItem = this.lv_GldnrActList.filter(item => item.curYn === 'Y')[0]

        if (!_.isEmpty(curItem)) {
          // GldnrActWeklySmupVO.acvmtYn (1=달성, 2=미달성, 3=진행중, 4=예정, 5=미진행)
          acvmtLabel = curItem.acvmtYn === '1' ? '달성' : curItem.acvmtYn === '2' ? '미달성' : curItem.acvmtYn === '3' ? '진행중' : curItem.acvmtYn === '4' ? '예정' : curItem.acvmtYn === '5' ? '미진행' : '-'
        }
      }
      return acvmtLabel
    },
    isFc() {
      return window.vue.getStore('userInfo').getters.getUserInfo.isFc === 'Y'
    },
    EvtCust_TotCnt() {
      let totCnt = 0
      this.evtCustList.forEach(item => {
        totCnt += item.cnt
      })

      return totCnt;
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
    * Function명 : fn_SelThmmActActul
    * 설명       : 현재 활동성과 조회
    ******************************************************************************/
    fn_SelThmmActActul() {
      const pParams = {
        cnsltNo: this.cnsltNo,
        strYmd: moment(moment().subtract(30, 'day')).format('YYYYMMDD'),
        endYmd: this.$bizUtil.getDateToString(new Date(), '')
      }
      const trnstId = 'txTSSAP00S9'
      this.post(this, pParams, trnstId, 'S').then(response => {
        
        if (response?.body) {
          // 현재 활동성과 조회
          if ( response.body.rsltCd === 'S') {
            this.lv_APThmmActActulSVO = response.body

            // AFC 이면서 무급일 경우 컨설턴트로 표기 ( 정지웅 요청 : 2019.02.25 )
            if ( ['AM','FS'].includes(this.fn_GetUserInfo?.srvcMngScCd) && this.lv_APThmmActActulSVO.chnlQulfNm === '무급') {
              this.lv_ChnlQulfNm = '컨설턴트'
            } else {
              this.lv_ChnlQulfNm = this.lv_APThmmActActulSVO.chnlQulfNm
            }

            // 실적 달성율 = ( 실적 / 목표금액 ) * 100
            this.lv_ActualRate = Math.round(((Number(this.lv_APThmmActActulSVO.avlPrfmAmt1) / Number(this.lv_APThmmActActulSVO.qulfClbyStndAmt)) || 0) * 100) //실적
            this.fn_AnimateCounter()
            
          } else {
            this.$addSnackbar(response.body.rsltMsg)
          }
        } else {
          this.$addSnackbar(response?.msgComm?.msgDesc)
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },
    fn_AnimateCounter() {
      const interval = setInterval(() => {
        if (this.lv_GaugeCount >= this.lv_ActualRate) {
          // clearInterval(interval)
          // return
        }
        this.lv_GaugeCount++
      }, this.fn_GetRateRange === '100' ? 10
       : this.fn_GetRateRange === '80' ? 20
       : this.fn_GetRateRange === '60' ? 30
       : this.fn_GetRateRange === '40' ? 40
       : 50
      )
    },

    /*********************************************************
     * Function명: fn_ScrollBefore
     * 설명: scroll 위치 복원
     *********************************************************/
    fn_ScrollBefore() {
      this.beforeScrollTop && this.$refs.activity.scrollTo(this.beforeScrollTop)
    },
    /******************************************************************************
    * Function명 : fn_GoldenRulePageMove
    * 설명       : 활동룰 포함 현황 조회 유무 페이지 이동
    ******************************************************************************/
    fn_GoldenRulePageMove(){
      console.log(" @@@@@@@@@@@  lv_gldnrActrExist >> "+this.lv_gldnrActrExist)
      if(this.lv_gldnrActrExist){
        console.log(" @@@@@@@@@@@  MSPAP308M >> ")
        this.$router.push({name: 'MSPAP308M', params:{prePageId:'MSPBC003M'}})
      }
    },
    /*********************************************************
     * Function명: fn_gldnrActrExist
     * 설명: 골든룰활동 컨설턴트 활동룰 포함 현황 조회
     *********************************************************/
    fn_gldnrActrExist(){
      let onpstOrgKndCd = this.getStore('userInfo').getters.getUserInfo.onpstOrgKndCd
      if(onpstOrgKndCd === '11' || onpstOrgKndCd === '20' || onpstOrgKndCd === '14' || onpstOrgKndCd === '13' || onpstOrgKndCd === '15'){
        this.lv_gldnrActrExist = true
      }
    },
    /******************************************************************************
     * Function명 : fn_SelListMemrdCust
     * 설명       : 이벤트 고객 조회 I/F 호출
    ******************************************************************************/
    fn_SelListMemrdCust() {
      let lv_Vm = this
      let pParams = {}
      let trnstId = 'txTSSAP03S1'
      let date = moment(new Date()).format('YYYYMMDD')
      
      pParams =
                { inqrStrYmd: date, // 시작일자
                  inqrEndYmd: date, // 종료일자
                  evtTypCd  : '',
                  cnsltNo : this.cnsltNo
                }

      lv_Vm.evtCustList = []

      let CustList = [
        {cnt:0, custNm:[], evtTypNm:'생일'},
        {cnt:0, custNm:[], evtTypNm:'상령일'},
        {cnt:0, custNm:[], evtTypNm:'계약1주년'},
        {cnt:0, custNm:[], evtTypNm:'만기일'}
      ]

      // lv_Vm.evtCustList = [
      //   {cnt:0, custNm:[{length:11, name:"AAAA BBBBB,"},{length:11, name:"AAAAA BBBBB,"},{length:29, name:"DSADDDDDa sasDASDASDA,"},{length:20, name:"dDASDASDASsad rewDASDA,"},{length:3, name:"rwer werrd,"}], evtTypNm:'생일'},
      //   {cnt:0, custNm:[], evtTypNm:'상령일'},
      //   {cnt:0, custNm:[], evtTypNm:'계약1주년'},
      //   {cnt:0, custNm:[], evtTypNm:'만기일'}
      // ]

      this.post(lv_Vm, pParams, trnstId, 'S')
        .then(function (response) {
          console.log('이벤트 고객 조회 >>>>>> ', response)
          if (response.body !== null && response.body.iCMemrdCustVO !== null && response.body.iCMemrdCustVO.length > 0) {
            let lv_MemrdCustList = response.body.iCMemrdCustVO

            lv_MemrdCustList.forEach(item => {
              let obj = {}
              // 생일
              if(item.memrdTypCd === '11' || item.memrdTypCd === '12' ||item.memrdTypCd === '13') {
                CustList[0].cnt ++
                obj.length = item.custNm.length
                obj.name = item.custNm + ","
                CustList[0].custNm.push(obj)
              }
              // 상령일
              if(item.memrdTypCd === '21' || item.memrdTypCd === '22' ) {
                CustList[1].cnt ++
                obj.length = item.custNm.length
                obj.name = item.custNm + ","
                CustList[1].custNm.push(obj)
              }
              // 계약기념일
              if(item.memrdTypCd === '33') {
                CustList[2].cnt ++
                obj.length = item.custNm.length
                obj.name = item.custNm + ","
                CustList[2].custNm.push(obj)
              }
              // 만기일
              if(item.memrdTypCd === '51') {
                CustList[3].cnt ++
                obj.length = item.custNm.length
                obj.name = item.custNm + ","
                CustList[3].custNm.push(obj)
              }
            })
            CustList.forEach(item => {
              let length = item.custNm.length
              if(length > 0) 
              item.custNm[length-1].name = item.custNm[length-1].name.replace(',','')
            })
            lv_Vm.evtCustList = CustList

            console.log("evtCustList",lv_Vm.evtCustList)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
     * Function명 : fn_curLoadData
     * 설명       : 골든룰 데이터 가져오기
    ******************************************************************************/
    async fn_curLoadData(){
      this.lv_Params = {
        cnsltNo : this.cnsltNo, 
        stndYm : this.fn_getDate(0)
      }
      this.iCGldnrActWeklySmupDtlVO = []
      let pParams = this.lv_Params
      var lv_Vm = this
      const trnstId = 'txTSSAP09S3'
      const auth = 'S'
      await this.post(lv_Vm, pParams, trnstId, auth).then(response => {
        if (response.body !== null && response.body.iCGldnrActWeklySmupVO !== null){
          if (response.body.iCGldnrActWeklySmupVO.length > 0) {
            let lv_GldnrActList = response.body.iCGldnrActWeklySmupVO
            lv_Vm.lv_GldnrActList = lv_GldnrActList
            
            for (let i = 0; i < lv_GldnrActList.length; i++) {
              let GldnrActList = {}
              if(lv_GldnrActList[i].curYn === 'Y') {
                lv_Vm.GldnrWekl = moment(lv_GldnrActList[i].gldnrMnStrYmd).format('M') + "월 " + lv_GldnrActList[i].weklyDgr + "주차 "
                lv_Vm.GldnrChk   = lv_Vm.lv_acvmtYnData.find(x => x.key === lv_GldnrActList[i].acvmtYn).label

                if(lv_GldnrActList[i].iCGldnrActWeklySmupDtlVO != null && lv_Vm.GldnrChk != '미진행'){
                  for(let j=0;j < lv_GldnrActList[i].iCGldnrActWeklySmupDtlVO.length; j++){
                    let tmpObj = {}
                    tmpObj.gldnrActTypNm         = lv_Vm.lv_AcvmtItmData.find(x => x.key === lv_GldnrActList[i].iCGldnrActWeklySmupDtlVO[j].gldnrActTypCd).label
                    tmpObj.actCnt                = lv_GldnrActList[i].iCGldnrActWeklySmupDtlVO[j].actCnt
                    tmpObj.gldnrActTypGoalVal01  = lv_GldnrActList[i].iCGldnrActWeklySmupDtlVO[j].gldnrActTypGoalVal01
                    lv_Vm.iCGldnrActWeklySmupDtlVO.push(tmpObj)
                    console.log("this.iCGldnrActWeklySmupDtlVO >>>",lv_Vm.iCGldnrActWeklySmupDtlVO)
                  }
                }
              }
            }
          }else{
          }
        }else{
        }
      }).catch(function (error) {
        window.vue.error(error)
        lv_Vm.getStore('confirm').dispatch('ADD', '조회 실패 했습니다.')
      })
    },
    /******************************************************************************
     * Function명 : fn_getDate
     * 설명       : 월 계산
    ******************************************************************************/
    fn_getDate(val){
      let d = new Date()
      if(val !== 0){
        let lastDay = (new Date(d.getFullYear(), d.getMonth(),0)).getDate()
        if(d.getDate() > lastDay){
          d.setDate(lastDay)
        }
        d.setMonth(d.getMonth() -1)
      }
      return d.getFullYear() + ('0'+(d.getMonth()+1)).slice(-2)
    },
    /*********************************************************
      * Function명: fn_SelectListPrdSchdl
      * 설명: 일정을 조회한다.
    *********************************************************/
    fn_SelectListPrdSchdl () {
      let lv_Vm = this
      var trnstId = 'txTSSAP20S5'

      if(localStorage.getItem('deviceType') == 'Tablet' && window.vue.getStore('userInfo').getters.getUserInfo.cnsltNo === lv_Vm.cnsltNo) {
        trnstId = 'txTSSAP20S7'
      }
        
      let date = moment(new Date()).format('YYYYMMDD')
      lv_Vm.SchdList = []
      var pParam = {
        userEno: this.cnsltNo,
        fcSchdlStrYmd: date,
        fcSchdlEndYmd: date
      }
      lv_Vm.post(lv_Vm, pParam, trnstId, 'S').then(function (response) {
        if (response.msgComm.msgCd === 'EFWS004') {
          // 실패시
          window.fdpbridge.exec('ToastPlugin', {message: response.msgComm.msgDesc}, () => {}, () => {})
        }
        if ( response.body != null && response.body.aPSchdlInfoSVO != null) {
          let lv_SchdList = response.body.aPSchdlInfoSVO

          let rruleJsonString  = ''
          let normalJsonString = ''
          let rruleData        = ''
          let normalData       = ''
          let setData          = ''

          // 테블릿의 경우, db에 저장된 핸드폰일정까지 조회
          if(localStorage.getItem('deviceType') == 'Tablet' && window.vue.getStore('userInfo').getters.getUserInfo.cnsltNo === lv_Vm.cnsltNo) {
            // 핸드폰 일정 중, 후처리가 필요한 rrule 관련 데이터만 추출
            rruleJsonString  = lv_SchdList.filter(item => (!lv_Vm.$bizUtil.isEmpty(item.schdlCretRlCntnt) || !lv_Vm.$bizUtil.isEmpty(item.smpSchdlOrgcpNo)) && item.fcSchdlScCd === '5' )
            normalJsonString = lv_SchdList.filter(item => (lv_Vm.$bizUtil.isEmpty(item.schdlCretRlCntnt) || lv_Vm.$bizUtil.isEmpty(item.smpSchdlOrgcpNo)) && item.fcSchdlScCd === '5' )
            // apUtil 후처리 로직에 맞게 세팅
            rruleData  = lv_Vm.$bizUtil.apUtils.fn_ExtSchdlSetForTablet(rruleJsonString)
            normalData = lv_Vm.$bizUtil.apUtils.fn_ExtSchdlSetForTablet(normalJsonString)  
            // 초기 핸드폰일정 데이터 형태로, rrule 데이터 처리
            setData = lv_Vm.$bizUtil.apUtils.parseJsonCal(JSON.stringify(rruleData), JSON.stringify(normalData), date, date)
          }

          lv_SchdList = lv_SchdList.filter(item => item.fcSchdlScCd !== '5')

          for (let i = 0; i < lv_SchdList.length; i++) {
            let tmpObj = {}
            let today = moment(new Date()).format('YYYYMMDD')
            if(!lv_Vm.$bizUtil.isEmpty(lv_SchdList[i].fcSchdlStrHm)) {
              if(lv_SchdList[i].fcSchdlStrYmd.replaceAll("-","") < today &&  lv_SchdList[i].fcSchdlEndYmd.replaceAll("-","") > today) {
                tmpObj.startTime = "[종일]"
              } else {
                tmpObj.compareTime = lv_SchdList[i].fcSchdlStrHm
                let hour24  = parseInt(lv_SchdList[i].fcSchdlStrHm.slice(0,2))
                let minutes = parseInt(lv_SchdList[i].fcSchdlStrHm.slice(2))

                let hour12  = hour24 % 12 || 12

                if(hour12.toString().length === 1) {
                  hour12 = "0" + hour12
                }
                if(minutes.toString().length === 1) {
                  minutes = "0" + minutes
                }

                tmpObj.startTime = "[" + hour12 + ":" + minutes + "]"

                if(lv_SchdList[i].fcSchdlStrHm.substr(0,2) > 11) {
                  tmpObj.afternoonYn = '오후'
                } else {
                  tmpObj.afternoonYn = '오전'
                }
              }
            } else {
              tmpObj.startTime = "[종일]"
            }
            tmpObj.custNm           = lv_SchdList[i].fcSchdlNm
            tmpObj.chnlCustId       = lv_SchdList[i].mobslChnlCustId
            tmpObj.SchdlNm          = lv_SchdList[i].fcSchdlCntnt
            tmpObj.fcSchdlSno       = lv_SchdList[i].fcSchdlSno
            tmpObj.fcSchdlScCd      = lv_SchdList[i].fcSchdlScCd
            tmpObj.fcSchdlPrmsPlcNm = lv_SchdList[i].fcSchdlPrmsPlcNm
            tmpObj.custCntcId       = lv_SchdList[i].custCntcId
            tmpObj.sharePmtImgNm    = lv_SchdList[i].sharePmtImgNm
            tmpObj.fcSchdlStrYmd    = lv_SchdList[i].fcSchdlStrYmd.replaceAll("-","")
            tmpObj.fcSchdlEndYmd    = lv_SchdList[i].fcSchdlEndYmd.replaceAll("-","")
            tmpObj.endTime          = lv_SchdList[i].fcSchdlEndHm
            if(lv_SchdList[i].fcSchdlScCd === '3') {
              tmpObj.CntcNm = '공유일정'
                            
              // 공유일정일 경우, 등록자 정보 세팅
              tmpObj.regntNo       = lv_SchdList[i].regntNo
              tmpObj.regntNm       = lv_SchdList[i].regntNm
              tmpObj.regntFofOrgNm = lv_SchdList[i].regntFofOrgNm
              tmpObj.regntDofOrgNm = lv_SchdList[i].regntDofOrgNm
              tmpObj.regntHofOrgNm = lv_SchdList[i].regntHofOrgNm

              // 차트보기 3개월 이전 일정인 경우, 활성화 class 제거
              let sDate = moment(tmpObj.startDate).format('YYYYMMDD') // 일정 시작일
              let d3Month = moment(new Date().setMonth(new Date().getMonth() - 3)).format('YYYYMMDD')

              tmpObj.isIn3Month = true
              if (lv_Vm.isFc && lv_Vm.getStore('userInfo').getters.getUserInfo.userId !== tmpObj.regntNo) {
                if (sDate < d3Month) {
                    tmpObj.isIn3Month = false
                }
              }
            } else if (lv_SchdList[i].fcSchdlScCd === '5') {
              tmpObj.CntcNm = '핸드폰일정'
            }

            let custCntc = null
            if (!lv_Vm.$bizUtil.isEmpty(tmpObj.custCntcId)) {
              if (tmpObj.custCntcId.indexOf('SH') > -1) {
                custCntc = apConstants.mngrCustCntc.filter(cntcItem => cntcItem.id === tmpObj.custCntcId)
              } else if (tmpObj.custCntcId.indexOf('HD') > -1) {
                custCntc = apConstants.fcCustCntc.filter(cntcItem => cntcItem.id === tmpObj.custCntcId)
              }
            }
            
            if (!_.isEmpty(custCntc)) {
              tmpObj.CntcNm = custCntc[0].name
            }

            console.log("tmpObj >>>>", tmpObj)
            lv_Vm.SchdList.push(tmpObj)
            
          }
          if(localStorage.getItem('deviceType') == 'Tablet' && window.vue.getStore('userInfo').getters.getUserInfo.cnsltNo === lv_Vm.cnsltNo) {
            for(let j=0; j<setData.length; j++) {
              let data = lv_Vm.$bizUtil.apUtils.setSchdlDataForSrn(setData[j], 2)
              lv_Vm.SchdList.push(data.tmpObj)
            }
            lv_Vm.fn_SchdlSort()
          }

          console.log("내 일정 데이터 >>>>", lv_Vm.SchdList)
        }
        lv_Vm.fn_ExtschdlLoad()
      }).catch(function (err) {
        console.log('MSPAP314M===>err', err)
      })
    },
    /*********************************************************
      * Function명: fn_callHis
      * 설명: 전화이력 조회한다.
    *********************************************************/
    fn_callHis() {
        console.log('오늘 전화이력')
        const lv_Vm   = this 
        const trnstId = 'txTSSAP06S1' 
        const auth    = 'S'

        lv_Vm.callList = []
        lv_Vm.callCnt = []

        let pParams   = {
          mobslCntcOccuYmd: moment(new Date()).format('YYYYMMDD'),
          eno             : lv_Vm.cnsltNo
        }
        
        console.log('param>>!', pParams)
        lv_Vm.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            console.log('response.body', response.body)
            if (response.body !== null && response.body.telHstrVO !== null && response.body.telHstrVO !== undefined) {
            let custList = response.body.telHstrVO

            custList.forEach((item) => {
              let tmpObj = {}
              if(item.mobslCustCntcId === 'HC08' || item.mobslCustCntcId === 'HC10' || item.mobslCustCntcId === 'HC11' || item.mobslCustCntcId === 'SEND' || item.mobslCustCntcId === 'RECI') {
                tmpObj.custNm          = item.custNm
                tmpObj.mobslCntcCntnt  = item.mobslCntcCntnt
                tmpObj.mobslCustCntcId = item.mobslCustCntcId

                if(!lv_Vm.$bizUtil.isEmpty(item.phclMemoCntnt)) {
                  tmpObj.phclMemoCntnt = item.phclMemoCntnt.replace(/(?:\r\n|\r|\n)/g, '<br/>')
                } else {
                  tmpObj.phclMemoCntnt = item.phclMemoCntnt
                }

                lv_Vm.callList.push(tmpObj)
                lv_Vm.callCnt.push(item.mobslChnlCustId)
              }
            })
            lv_Vm.callCnt = [...new Set(lv_Vm.callCnt)]
            console.log("lv_Vm.callList", lv_Vm.callList)
          }
        })
      },
      /*********************************************************
       * Function명: fn_SelectHolidayList
       * 설명: 휴일 목록을 조회한다.
       *********************************************************/
      fn_SelectHolidayList () {
        let lv_Vm = this

        let trnstId = 'txTSSAP03S2'
        let pParam = {
          inqrStrYmd: moment(new Date()).format('YYYYMMDD'),
          inqrEndYmd: moment(new Date()).format('YYYYMMDD')
        }
        lv_Vm.post(lv_Vm, pParam, trnstId, 'S').then(function (response) {
          let iCYmdMngVO = response.body.iCYmdMngVO
          if ( response.body != null && iCYmdMngVO != null && iCYmdMngVO.length > 0) {
            if(iCYmdMngVO[0].holyCd === '1') {
              lv_Vm.holiday = iCYmdMngVO[0].holyNm
            }
          }
        })
      },
      /******************************************************************************
      * Function명  : fn_SelDeltParngCustCnt ()
      * 설명        : 홈화면 배너용 삭제예정고객 수 조회
      * Params      : N/A
      * Return      :
      ******************************************************************************/
      fn_SelDeltParngCustCnt () {
        const  lv_Vm = this
        const trnstId = 'txTSSBC41S4'
        const auth = 'S'

        let pParams = {
          cnsltNo : this.cnsltNo
        }        

        console.log("txTSSBC41S4 >>>>>>>>")

        return this.post(lv_Vm, pParams, trnstId, auth, {}, true).then(function (response) {
          if (response?.body) {
            if ( response.body.iCDeltParngCustVO?.length ) {   
              let deltList = response.body.iCDeltParngCustVO
              lv_Vm.lv_ICDeltParngCust = response.body.iCDeltParngCustVO
              lv_Vm.fn_GetDelCustList(deltList)
            }
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
      },
      /******************************************************************************
      * Function명  : fn_ManagerSearch
      * 설명        : (지점장) 지점 FC 활동 조회
      ******************************************************************************/
      fn_ManagerSearch() {
        let lv_vm = this
        lv_vm.getStore('bcStore').dispatch('SPEAKER_YN', false)
        lv_vm.getStore('bcStore').dispatch('FC_ACT_INFO_BTN_SHOW', false)
        console.log('store speaker Y')
        this.popupAP106 = this.$bottomModal.open(MSPAP106P, {
          properties: {
            pPage: 'MSPBC003M',
            pData: this.fcData,
            fcDtlData : this.fcDtlData
          },
          listeners: {
            onPopupSearch: (pItem, pData) => {
              console.log('store speaker N')
              lv_vm.getStore('bcStore').dispatch('SPEAKER_YN', true)
              lv_vm.getStore('bcStore').dispatch('FC_ACT_INFO_BTN_SHOW', true)
              
              console.log('onPopupSearch 컨설턴트 >> ' , pItem)
              console.log('onPopupSearch 컨설턴트 상세 >> ' , pData)
              // 모달 닫기
              lv_vm.$bottomModal.close(this.popupAP106)
              if(pItem != null && pItem != undefined){
                
                lv_vm.cnsltNm   = pItem.label
                lv_vm.cnsltNo   = pItem.key

                lv_vm.fcData = pItem     // router params 데이터                
                lv_vm.fcDtlData = pData

                lv_vm.fn_searhData()
              }
            },
            onPopupClose: () => {
              console.log('store speaker N')
              lv_vm.getStore('bcStore').dispatch('SPEAKER_YN', true)
              lv_vm.getStore('bcStore').dispatch('FC_ACT_INFO_BTN_SHOW', true)
              console.log('onPopupClose')
              // 모달 닫기
              this.$bottomModal.close(this.popupAP106)
            }
          }
        })
      },
      /******************************************************************************
      * Function명  : fn_searhData
      * 설명        : 데이터 조회
      ******************************************************************************/
      fn_searhData() {

        // 삭제예정고객 수 조회
        this.fn_SelDeltParngCustCnt()

        // 당월 실적조회
        this.lv_IsShowGauge && this.fn_SelThmmActActul()

        // 골든룰현황 조회
        this.fn_gldnrActrExist()

        // 활동캘린더 조회
        this.fn_SelListMemrdCust()
      
        // 골든룰 데이터 조회
        this.fn_curLoadData()
        
        // 일정 조회
        this.fn_SelectListPrdSchdl()
      
        // 전화이력 조회
        this.fn_callHis()
      
        // scroll 위치 복원
        this.fn_ScrollBefore()
      },
      /******************************************************************************
      * Function명  : fn_Init
      * 설명        : 변수 초기화
      ******************************************************************************/
      fn_Init() {
        this.fcData                        = {}
        this.SchdList                      = []
        this.evtCustList                   = []
        this.iCGldnrActWeklySmupDtlVO      = []
        this.callList                      = []
        this.smryText                      = ''
        this.lv_DeltCustList               = [[],[],[],[]]
        this.lv_ChnlQulfNm                 = ''
        this.lv_ActualRate                 = 0
        this.achievValue                   = 20
        this.runFlagSwitch                 = false
        this.startFlagSwitch               = false
        this.flagMoveValue                 = '0'
        this.percentNumberMoveValue        = '0'
        this.achievValueCounter            = 0
        this.achievValueTimeout            = null
        this.achievValueIntervalTimer      = null
        this.achievValueTimerSwitch        = false
        this.currentScrollTopIndex         = 0
        this.activityMessageTransformValue = '0'
        this.deltCustData                  = []
        
      },
      catchTouchEvent(event) {
        // 함수 추가 2025.1.10 :chan
        // scroll button과 전체 scroll과의 event 충돌 피하기
        if(event.type === 'touchstart'){
          this.scrollBtnLeftStart =  document.querySelector('.activityScrollBtnWrap').scrollLeft
          document.querySelector('.todayActivityPage').removeEventListener('scroll', this.catchScrollEvent, true)
        } else if(event.type === 'touchend'){
          this.scrollBtnLeftEnd = document.querySelector('.activityScrollBtnWrap').scrollLeft
          if(this.scrollBtnLeftStart === this.scrollBtnLeftEnd){
            document.querySelector('.todayActivityPage').addEventListener('scroll', this.catchScrollEvent, true)
          }
        } else if(event.type === 'scrollend'){
          document.querySelector('.todayActivityPage').addEventListener('scroll', this.catchScrollEvent, true)
        }
      },
      catchScrollEvent(event) {
        // 전체 수정 2025.1.10 :chan
        // scroll 내리면서 '이달의 업적'이 화면에 보일 때 character animation 실행
        let scrollAmount = document.querySelector('.mo-page__contents-wrapper').scrollTop
        let activityAchievWrapWidth = document.querySelector('.activityAchievBg').clientWidth
        let myAchievWrapOffsetTop = document.querySelector('#myAchievWrap').offsetTop
        let scrollGap = Math.abs(scrollAmount - myAchievWrapOffsetTop) - 100

        let activityScrollBtnWrap = document.querySelector('.activityScrollBtnWrap')
        if(activityScrollBtnWrap.offsetTop > 140) activityScrollBtnWrap.classList.add('onScroll') // 그림자 class 생성
        else activityScrollBtnWrap.classList.remove('onScroll')

        if (this.zero100AchievValue > 0 && scrollGap < this.documentScale.height*0.4){
          let availableWidth = Math.ceil((activityAchievWrapWidth * this.zero100AchievValue) / 100)
          let remainWidth = activityAchievWrapWidth - availableWidth
          this.runFlagSwitch = true
          this.startFlagSwitch = true
          this.animateAchievValueBox(this.zero100AchievValue)
          this.flagMoveValue = this.zero100AchievValue + '%'
          if(20 > availableWidth){
            this.percentNumberMoveValue = '20px'
          } else {
            this.percentNumberMoveValue = this.zero100AchievValue + '%'
          }
        }
        // scroll 바닥이면 함수 종료
        let documentScrollTop = document.querySelector('.mo-page__contents-wrapper').scrollTop
        let scrollToAllHeight = document.querySelector('.myWork_container').clientHeight
        if(scrollToAllHeight - documentScrollTop < 480){ return } // 475px

        // 현재 scroll 위치에 따라 scroll button 변경
        let scrollCurrent = scrollAmount + 120 // 여유값
        let scrollElements = document.getElementsByClassName('scrollTo')
        let lastScrollTo = document.getElementsByClassName('scrollTo')[document.getElementsByClassName('scrollTo').length-1].offsetTop
        for(let i = scrollElements.length-1; i >= 0; i--){
          if(scrollCurrent >= scrollElements[i].offsetTop){
            this.currentScrollToIndex = i
            break
          }
        }
        if(scrollCurrent < lastScrollTo) {
          let activityScrollBtn = document.getElementsByClassName('activityScrollBtn')
          for(let i = 0; i < activityScrollBtn.length; i++){
            activityScrollBtn[i].classList.remove('checked')
          }
          activityScrollBtn[this.currentScrollToIndex].classList.add('checked')

          let scrollButtonLeft = 0
          for(let j = 0; j < this.currentScrollToIndex; j++){
            scrollButtonLeft += activityScrollBtn[j].clientWidth + 5 // flex gap
          }
          document.querySelector('.activityScrollBtnWrap').scrollTo({left:scrollButtonLeft - activityScrollBtn[this.currentScrollToIndex].clientWidth, top:0})
        }
      },
      animateAchievValueBox(value) {
        if(this.achievValueTimerSwitch === false){
          this.achievValueTimeout = setTimeout( () => {
            this.achievValueIntervalTimer = setInterval( () => {
            if(this.achievValueCounter < value){
              this.achievValueCounter++
            } else {
              clearInterval(this.achievValueIntervalTimer)
              this.achievValueIntervalTimer = null
            }}, 20) // 숫자 바뀌는 속도 (ms)
          }, 500) // 숫자 변경 시작 지연 시간 (ms)
          this.achievValueTimerSwitch = true
        }
      },
      scrollButtonCheck(index) {
        let activityScrollBtn = document.getElementsByClassName('activityScrollBtn')
        for(let i = 0; i < activityScrollBtn.length; i++){
          activityScrollBtn[i].classList.remove('checked')
        }
        document.getElementsByClassName('activityScrollBtn')[index].classList.add('checked')

        // 해당 contents로 scroll 이동
        let scrollBtnHeight = document.querySelector('.activityScrollBtnWrap').clientHeight
        let scrollAmount = document.getElementsByClassName('scrollTo')[index].offsetTop - scrollBtnHeight - 10
        document.querySelector('.mo-page__contents-wrapper').scrollTo({left:0, top:scrollAmount})
      },
      showAllSchedule() {
        // schedule 길게 펼쳐보기 2024.12.20 :chan
        if(this.scheduleAmount < this.SchdList.length){
          this.scheduleAmount = this.SchdList.length
          this.showMore = '줄이기'
        } else {
          this.scheduleAmount = 3
          this.showMore = '더보기'
        }
      },
      showAllCallList() {
        // Call List 펼쳐보기 2024.12.20 :chan
        if(this.callListAmount < this.callList.length){
          this.callListAmount = this.callList.length
          this.showAllList = '줄이기'
          this.showAllIcon = 'minus'
        } else {
          this.callListAmount = 3
          this.showAllList = '더보기'
          this.showAllIcon = 'plus'
        }
      },

      /******************************************************************************
      * Function명  : fn_TodayText
      * 설명        : 오늘의 활동 읽어주기 문구 설정
      ******************************************************************************/
      fn_TodayText(value) {
        this.smryText = ''

        let schdl = ''
        let memrd = ''
        let gldnr = ''
        let deltParng = ''

        // 인트로 셋팅
        let intro = ''
        if(this.$bizUtil.isEmpty(this.holiday)) {
          intro = this.date + ' ' + this.day + '요일 오늘의 활동을 안내해드릴게요.' 
        } else {
          intro =  this.date + this.day + '요일 ' + this.holiday + ' 오늘의 활동을 안내해드릴게요.'
        }

        // 1: 요약 듣기, 2: 자세히 듣기
        if(value === 2) {
          // 일정
          if(this.SchdList.length > 0) {
            schdl = "오늘 등록된 일정입니다. "
            this.SchdList.forEach(item => {
              let time = item.startTime.replace(/[\[\]]/g,"").split(":")

              // 활동일정
              if(item.fcSchdlScCd === '1') {
                if(!item.startTime.includes("종일")) {
                  // 장소 유무
                  if(!this.$bizUtil.isEmpty(item.fcSchdlPrmsPlcNm)) {
                    if(!this.$bizUtil.isEmpty(item.CntcNm)) {
                      schdl = schdl + item.afternoonYn + time[0] + '시' + time[1] + '분 ' + item.custNm + '고객님과 ' + item.CntcNm + ' 일정이 ' + item.fcSchdlPrmsPlcNm + '에서 있습니다. '
                    } else {
                      schdl = schdl + item.afternoonYn + time[0] + '시' + time[1] + '분 ' + item.custNm + '고객님과 '  +  '일정이 ' + item.fcSchdlPrmsPlcNm + '에서 있습니다. '
                    }
                  } else {
                    if(!this.$bizUtil.isEmpty(item.CntcNm)) {
                      schdl = schdl + item.afternoonYn + time[0] + '시' + time[1] + '분 ' + item.custNm + '고객님과 ' + item.CntcNm + ' 일정이 있습니다. '
                    } else {
                      schdl = schdl + item.afternoonYn + time[0] + '시' + time[1] + '분 ' + item.custNm + '고객님과 ' + ' 일정이 있습니다. '
                    }
                  }
                } else {
                  if(!this.$bizUtil.isEmpty(item.fcSchdlPrmsPlcNm)) {
                    schdl = schdl + item.custNm + '고객님과 ' + item.CntcNm + ' 일정이 ' + item.fcSchdlPrmsPlcNm + '에서 있습니다. '
                  } else {
                    schdl = schdl + item.custNm + '고객님과 ' + item.CntcNm + ' 일정이 있습니다. '
                  }
                }
              }

              // 개인일정
              if(item.fcSchdlScCd === '2') {
                if(!item.startTime.includes("종일")) {
                  // 장소 유무
                  if(!this.$bizUtil.isEmpty(item.fcSchdlPrmsPlcNm)) {
                    schdl = schdl + item.afternoonYn  + time[0] + '시' + time[1] + '분 ' + item.custNm + '일정이 ' + item.fcSchdlPrmsPlcNm + '에서 있습니다. '
                  } else {
                    schdl = schdl + item.afternoonYn  + time[0] + '시' + time[1] + '분 ' + item.custNm + '일정이 있습니다. '
                  }
                } else {
                  if(!this.$bizUtil.isEmpty(item.fcSchdlPrmsPlcNm)) {
                    schdl = schdl + item.custNm + '일정이 ' + item.fcSchdlPrmsPlcNm + '에서 있습니다. '
                  } else {
                    schdl = schdl + item.custNm + '일정이 있습니다. '
                  }
                }
              }

              // 공유일정
              if(item.fcSchdlScCd === '3') {
                if(!item.startTime.includes("종일")) {
                  // 장소 유무
                  if(!this.$bizUtil.isEmpty(item.fcSchdlPrmsPlcNm)) {
                    schdl = schdl + item.afternoonYn  + time[0] + '시' + time[1] + '분 ' + item.custNm + '일정이 ' + item.fcSchdlPrmsPlcNm + '에서 있습니다. '
                  } else {
                    schdl = schdl + item.afternoonYn  + time[0] + '시' + time[1] + '분 ' + item.custNm + '일정이 있습니다. '
                  }
                } else {
                  if(!this.$bizUtil.isEmpty(item.fcSchdlPrmsPlcNm)) {
                    schdl = schdl + item.custNm + '일정이 ' + item.fcSchdlPrmsPlcNm + '에서 있습니다. '
                  } else {
                    schdl = schdl + item.custNm + '일정이 있습니다. '
                  }
                }
              }

              // 핸드폰일정
              if(item.fcSchdlScCd === '5') {
                if(!item.startTime.includes("종일")) {
                  // 장소 유무
                  if(!this.$bizUtil.isEmpty(item.fcSchdlPrmsPlcNm)) {
                    schdl = schdl + item.afternoonYn  + time[0] + '시' + time[1] + '분 ' + item.custNm + '일정이 ' + item.fcSchdlPrmsPlcNm + '에서 있습니다. '
                  } else {
                    schdl = schdl + item.afternoonYn  + time[0] + '시' + time[1] + '분 ' + item.custNm + '일정이 있습니다. '
                  }
                } else {
                  if(!this.$bizUtil.isEmpty(item.fcSchdlPrmsPlcNm)) {
                    schdl = schdl + item.custNm + '일정이 ' + item.fcSchdlPrmsPlcNm + '에서 있습니다. '
                  } else {
                    schdl = schdl + item.custNm + '일정이 있습니다. '
                  }
                }
              }

              schdl = schdl.replace("00분", "")
            })
          }

          // 이벤트
          if(this.evtCustList.length > 0) {
            memrd = '오늘 특별한 날을 맞이한 고객입니다. '
            this.evtCustList.forEach(item => {
              let nmList = ''
              item.custNm.forEach(e => {
                nmList = nmList + e.name
              })

              //0건인 항목 제외 2025-01-17
              if(item.cnt>0){
                memrd = memrd + item.evtTypNm + '고객은 ' + nmList + ' ' + item.cnt + '명 있습니다.' 
              }
            })
          }

          // 동의만료
          // this.lv_DeltCustList[1] > D-day
          // this.lv_DeltCustList[2] > D-1
          // this.lv_DeltCustList[3] > D-2
          // this.lv_DeltCustList[4] > D-3

          let deltCustList = this.lv_DeltCustList
          let deltCustTotCnt = 0
          deltCustList.forEach(item => {
            if (item.evtTypNm !== '필컨만료' && !this.$bizUtil.isEmpty(item.totCnt)) {
              deltCustTotCnt += item.totCnt
            }
          })

          if (deltCustTotCnt > 0) {
            if (!_.isEmpty(deltCustList) && deltCustTotCnt > 0) {
              deltParng = '3일 내 필수컨설팅 동의가 만료되는 고객이 ' + deltCustTotCnt + '명 있습니다. '
  
              if (!_.isEmpty(this.lv_DeltCustList[1]) && this.lv_DeltCustList[1].totCnt>0) {
                deltParng += '오늘 만료되는 고객은 ' + this.lv_DeltCustList[1].custNmList + ', '
              }
              if (!_.isEmpty(this.lv_DeltCustList[2]) && this.lv_DeltCustList[2].totCnt>0) {
                deltParng += '만료가 1일 남은 고객은 ' + this.lv_DeltCustList[2].custNmList + ', '
                }
              if (!_.isEmpty(this.lv_DeltCustList[3]) && this.lv_DeltCustList[3].totCnt>0) {
                deltParng += '만료가 2일 남은 고객은 ' + this.lv_DeltCustList[3].custNmList + ', '
                }
              if (!_.isEmpty(this.lv_DeltCustList[4]) && this.lv_DeltCustList[4].totCnt>0) {
                deltParng += '만료가 3일 남은 고객은 ' + this.lv_DeltCustList[4].custNmList + ', '
              }
              
              
              deltParng += '고객님의 동의를 확인해보세요. '
            }
          }

          // 골든룰
          if(this.GldnrChk === '진행중' || this.GldnrChk === '달성') {
            if(this.GldnrChk === '진행중') {
              gldnr = this.GldnrWekly + ' 활동골든룰은 현재 미달성 중입니다.'
              this.iCGldnrActWeklySmupDtlVO.forEach(item => {
                if((Number(item.gldnrActTypGoalVal01) - Number(item.actCnt)) > 0) {
                  gldnr = gldnr + item.gldnrActTypNm + ' ' + (Number(item.gldnrActTypGoalVal01)-Number(item.actCnt)) + '건 '
                }
              })
              gldnr = gldnr + '이 더 필요합니다.'
            }
            if(this.GldnrChk === '달성') {
              gldnr = this.GldnrWekly + ' 활동골든룰은 이미 달성하셨습니다.'
            }
          }
        } else {
          // 일정
          if(this.SchdList.length > 0) {
            schdl = "오늘 등록된 일정입니다. "
            this.SchdList.forEach(item => {
              let time = item.startTime.replace(/[\[\]]/g,"").split(":")

              // 활동일정
              if(item.fcSchdlScCd === '1') {
                  if(!item.startTime.includes("종일")) {
                    schdl = schdl + item.afternoonYn + time[0] + '시' + time[1] + '분 ' + item.custNm + '고객. '
                  } else {
                    schdl = schdl + item.custNm + '고객. '
                  }
              }

              // 개인일정
              if(item.fcSchdlScCd === '2') {
                if(!item.startTime.includes("종일")) {
                  schdl = schdl + item.afternoonYn + time[0] + '시' + time[1] + '분 ' +  item.custNm + '. '
                } else {
                  schdl = schdl + item.custNm + '. '
                }
              }

              // 공유일정
              if(item.fcSchdlScCd === '3') {
                if(!item.startTime.includes("종일")) {
                  schdl = schdl + item.afternoonYn + time[0] + '시' + time[1] + '분 ' +  item.custNm + '. '
                } else {
                  schdl = schdl + item.custNm + '. '
                }
              }

              // 핸드폰일정
              if(item.fcSchdlScCd === '5') {
                if(!item.startTime.includes("종일")) {
                  schdl = schdl + item.afternoonYn + time[0] + '시' + time[1] + '분 ' +  item.custNm + '. '
                } else {
                  schdl = schdl + item.custNm + '. '
                }
              }

              schdl = schdl.replace("00분", "")
            })
            schdl = schdl + '일정이 있습니다. '
          }

          // 이벤트
          if(this.evtCustList.length > 0) {
            memrd = '오늘 특별한 날을 맞이한 고객입니다. '
            this.evtCustList.forEach(item => {
              if(item.cnt>0){
                memrd = memrd + item.evtTypNm + '인 고객은 ' +  item.cnt + '명 ' 
              }
            })
            memrd = memrd + ' 있습니다. '
          }

          // 동의만료
          // this.lv_DeltCustList[1] > D-day
          // this.lv_DeltCustList[2] > D-1
          // this.lv_DeltCustList[3] > D-2
          // this.lv_DeltCustList[4] > D-3

          let deltCustList = this.lv_DeltCustList
          let deltCustTotCnt = 0
          deltCustList.forEach(item => {
            if (item.evtTypNm !== '필컨만료' && !this.$bizUtil.isEmpty(item.totCnt)) {
              deltCustTotCnt += item.totCnt
            }
          })

          if (deltCustTotCnt > 0) {
            if (!_.isEmpty(deltCustList) && deltCustTotCnt > 0) {
              deltParng = '3일 내 필수컨설팅 동의가 만료되는 고객이' + deltCustTotCnt + '명 있습니다.'
  
              if (!_.isEmpty(deltCustList[1]) && deltCustList[1].totCnt>0) {
                deltParng += '오늘 만료되는 ' + deltCustList[1].totCnt + '명의 동의를 확인해보세요.'
              }
            }
          }

          // 골든룰
          if(this.GldnrChk === '진행중' || this.GldnrChk === '달성') {
            if(this.GldnrChk === '진행중') {
              gldnr = this.GldnrWekly + ' 활동골든룰은 현재 미달성 중입니다.'
              this.iCGldnrActWeklySmupDtlVO.forEach(item => {
                if((Number(item.gldnrActTypGoalVal01) - Number(item.actCnt)) > 0) {
                  gldnr = gldnr + item.gldnrActTypNm + ' ' + (Number(item.gldnrActTypGoalVal01) - Number(item.actCnt)) + '건 '
                }
              })
              gldnr = gldnr + '이 더 필요합니다. '
            }
            if(this.GldnrChk === '달성') {
              gldnr = this.GldnrWekly + ' 활동골든룰은 이미 달성하셨습니다. '
            }
          }
        }

        let outro = '오늘 하루 활동도 화이팅입니다.'

        this.smryText = intro + schdl + memrd + deltParng + gldnr + outro
        
        if(schdl === '' && memrd === '' &&  deltParng === '' ) {
          this.smryText = intro + '아직 등록된 일정이 없습니다. 고객과 통화 후 전화메모를 남겨보거나 일정을 계획해 보세요.' + gldnr + outro
        }
        console.log("TTS 문장 확인 >>>>> ", this.smryText)
      },
      getStyle(data) {
        return data < 20 ? 'white-space: nowrap' : ''
      },
      /******************************************************************************
      * Function명  : fn_schlAdd
      * 설명        : 일정등록
      ******************************************************************************/
      fn_schlAdd() {
        let lv_vm = this
        lv_vm.getStore('bcStore').dispatch('SPEAKER_YN', false)
        lv_vm.getStore('bcStore').dispatch('FC_ACT_INFO_BTN_SHOW', false)
        this.fullPopup = this.$bottomModal.open(MSPAP302D, {
            properties: {
            pPage: 'MSPBC003M',
            pIsBackKeyListen: true,
            pFcSchdlSno: 0,
            pMobslChnlCustId: '',
            pCustAlctnCmpgnId: '',
            pChoiceYmd: moment(new Date()).format('YYYY-MM-DD'),
            pSrnId: 'MSPBC003M'
          },
          listeners: {
            closeDivision: () => {
              console.log('closeDivision')
              this.$bottomModal.close(this.fullPopup)
              lv_vm.getStore('bcStore').dispatch('SPEAKER_YN', true)
              lv_vm.getStore('bcStore').dispatch('FC_ACT_INFO_BTN_SHOW', true)
            },
            onPopupClose: () => {
              console.log('onPopupClose')
              this.$bottomModal.close(this.fullPopup)
              this.fn_SelectListPrdSchdl()
              lv_vm.getStore('bcStore').dispatch('SPEAKER_YN', true)
              lv_vm.getStore('bcStore').dispatch('FC_ACT_INFO_BTN_SHOW', true)
            },
            fn_OnRefresh: () => {
              this.fn_SelectListPrdSchdl()
              this.$refs.schListDtl.close()
            }
          }
        })
      },
      /******************************************************************************
      * Function명  : fn_ExtschdlLoad
      * 설명        : 핸드폰일정조회
      ******************************************************************************/
     async fn_ExtschdlLoad() {
        let lv_Vm      = this
        let rruleData  = ''
        let normalData = ''

        //rrule 일정 조회기간
        let rruleEnd     = Number(moment(new Date()).format('YYYY')) + 5 + '1231'
        let rruleStrart  = Number(moment(new Date()).format('YYYY')) - 5 + '0101'


        //normal 일정 조회기간
        let today  = moment(new Date()).format('YYYYMMDD') 
        today      = new Date(parseInt(today.substring(0,4)), parseInt(today.substring(4,6)), parseInt(today.substring(6,8)))

        let strDay = new Date(today)
        strDay.setMonth(strDay.getMonth() - 4)
        strDay.setDate(1)
        strDay = moment(strDay).format('YYYYMMDD') 

        let endDay = new Date(today)
        endDay.setMonth(endDay.getMonth() + 3)
        endDay.setDate(0)
        endDay = moment(endDay).format('YYYYMMDD') 

        // 로컬 테스트
        if (process.env.NODE_ENV === 'local') {
          this.fn_viewExtSchl()
          return false
        }
        if(await this.$bizUtil.telSrvcUserChk() && window.vue.getStore('userInfo').getters.getUserInfo.isBMngr === 'N' && localStorage.getItem('CLDR_SYNC_YN') === 'Y') {
          window.fdpbridge.exec('calendarGetInfoPlugin', {mode:'1', startDay:rruleStrart, endDay:rruleEnd }, function (result) {
            // 성공콜백
            console.log("rrule 성공", result.data)
            rruleData = result.data
            window.fdpbridge.exec('calendarGetInfoPlugin', {mode:'2', startDay:strDay, endDay:endDay}, function (result) {
              // 성공콜백
              console.log("normal 성공", result.data)
              normalData = result.data
              lv_Vm.fn_viewExtSchl(rruleData, normalData)
            },(error) => {
              lv_Vm.$bizUtil.apUtils.saveSysInfo('N')
              console.log('normal 실패', error)
            })
          },(error) => {
            lv_Vm.$bizUtil.apUtils.saveSysInfo('N')
            console.log('rrule 실패', error)
          })
        }
      },
      /******************************************************************************
      * Function명  : fn_viewExtSchl
      * 설명        : 핸드폰일정세팅
      ******************************************************************************/
      fn_viewExtSchl(rruleData, normalData) {
        
        // TEST DATA
        if (process.env.NODE_ENV === 'local') {
          rruleData  = "[{\"_id\":\"1011\",\"account_type\":\"com.google\",\"all_day\":\"0\",\"calendar_displayName\":\"slifeaccesstoken@gmail.com\",\"description\":\"\",\"dtend\":\"19700101,0900\",\"dtstart\":\"20250224,0900\",\"event_location\":\"\",\"owner_account\":\"slifeaccesstoken@gmail.com\",\"rrule\":\"FREQ\\u003dWEEKLY;INTERVAL\\u003d2;WKST\\u003dSU;BYDAY\\u003dWE\",\"title\":\"테스트3\"}]"
          normalData = "[{\"_id\":\"1103\",\"account_type\":\"com.google\",\"all_day\":\"0\",\"calendar_displayName\":\"slifeaccesstoken@gmail.com\",\"description\":\"\",\"dtend\":\"20250225,1400\",\"dtstart\":\"20250224,0800\",\"event_location\":\"\",\"owner_account\":\"slifeaccesstoken@gmail.com\",\"rrule\":\"\",\"title\":\"테스트111\"}, {\"_id\":\"1088\",\"account_type\":\"com.google\",\"all_day\":\"0\",\"calendar_displayName\":\"slifeaccesstoken@gmail.com\",\"description\":\"\",\"dtend\":\"20250131,1500\",\"dtstart\":\"20250221,1400\",\"event_location\":\"\",\"owner_account\":\"slifeaccesstoken@gmail.com\",\"rrule\":\"\",\"title\":\"테스트2\"}, {\"_id\":\"1089\",\"account_type\":\"com.google\",\"all_day\":\"0\",\"calendar_displayName\":\"slifeaccesstoken@gmail.com\",\"description\":\"ㄷㄷㄷㄷㄷㄷㄷㄷㄷㄷㄷㄷㄷㄷㄷㄷㄷㄷ더ㅗㅗㅗㅗㅗㅓㅜㅝ더더더더더더거거거우우ㅜㅜㅜㅜㅜㅡㅡㅡㅡㅡㅜㅜ\",\"dtend\":\"20250205,0900\",\"dtstart\":\"20250205,0800\",\"event_location\":\"\",\"owner_account\":\"slifeaccesstoken@gmail.com\",\"rrule\":\"\",\"title\":\"\"}, {\"_id\":\"1090\",\"account_type\":\"com.google\",\"all_day\":\"0\",\"calendar_displayName\":\"slifeaccesstoken@gmail.com\",\"description\":\"\",\"dtend\":\"20250205,0900\",\"dtstart\":\"20250205,0800\",\"event_location\":\"\",\"owner_account\":\"slifeaccesstoken@gmail.com\",\"rrule\":\"\",\"title\":\"테스트33\"}, {\"_id\":\"1091\",\"account_type\":\"com.google\",\"all_day\":\"0\",\"calendar_displayName\":\"slifeaccesstoken@gmail.com\",\"description\":\"\",\"dtend\":\"20250205,0900\",\"dtstart\":\"20250205,0800\",\"event_location\":\"\",\"owner_account\":\"slifeaccesstoken@gmail.com\",\"rrule\":\"\",\"title\":\"테스트4\"}]"
        }
        try {        
          const rruleJsonString  = rruleData
          const normalJsonString = normalData

          // 기간설정
          let today      = moment(new Date()).format('YYYYMMDD')                                                // 오늘 날짜
          // let day        = new Date(today.substring(0,4), today.substring(4,6) -1, today.substring(6,8))     // 조회기간
          // let searchDate = null                                                                              // 조회날짜

          // 화면에 보여줄 데이터만 가공
          let extSchdl = this.$bizUtil.apUtils.parseJsonCal(rruleJsonString, normalJsonString, today, today)
          
          for (let i = 0; i < extSchdl.length; i++) {
            let data = this.$bizUtil.apUtils.setSchdlDataForSrn(extSchdl[i], 2)
            this.SchdList.push(data.tmpObj)            
          }

          this.fn_SchdlSort()
        } catch(error) {
          console.log('error', error)
        }
      },
      /******************************************************************************
      * Function명 : fn_OpenCustInfoBs
      * 설명       : 네임UI 바텀시트 (고객명터치시)
      ******************************************************************************/
      fn_OpenCustInfoBs (item) {
        const lv_Vm = this
        
        if (item.fcSchdlScCd !== '1') return // 활동일정이 아니면 (1:활동/2:개인/3:공유)

        let cnsltNo = window.vue.getStore('userInfo').getters.getUserInfo.cnsltNo
        let chnlCustId = item.chnlCustId
        let custNm = item.custNm
        let nmUiObject = {}

        nmUiObject = {
          chnlCustId : chnlCustId, //고객채널 ID
          parentId   : lv_Vm.$options.screenId, //화면ID+
          cnsltNo : cnsltNo,
          custNm : custNm,
          contNo : ''
        }

        lv_Vm.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject,(modalId)=>{
          //Close 콜백 처리
          return
        })
      },

      /******************************************************************************
      * Function명 : fn_moveSchl
      * 설명       : 내 일정 화면으로 전환
      ******************************************************************************/
      fn_moveSchl() {
        this.$router.push({name: 'MSPAP314M', params: {pScrId: this.$options.screenId, pData: this.fcData, selectSchd: true}})
      },

      /******************************************************************************
      * Function명 : fn_GetDelCustList
      * 설명       : 삭제예정고객 D-day 날짜별로 셋팅
      *              3일 전까지 셋팅한다.
      ******************************************************************************/
      fn_GetDelCustList(deltList) {
        let date = moment(new Date()).format('YYYYMMDD')
        let endDate = moment(new Date().setDate(new Date().getDate() + 3)).format('YYYYMMDD')

        // D-3일까지 추출
        let pDeltList = deltList.filter(item => {
            item.cnsntDDay = moment(item.mndtCnstgCnsntEndYmd).diff(date, 'days')
            return item.cnsntDDay >= 0 && item.cnsntDDay <= 3
          })

        let deltList1 = pDeltList.filter(item => item.cnsntDDay === 0) // D-day
        let deltList2 = pDeltList.filter(item => item.cnsntDDay === 1) // D-1
        let deltList3 = pDeltList.filter(item => item.cnsntDDay === 2) // D-2
        let deltList4 = pDeltList.filter(item => item.cnsntDDay === 3) // D-3

        this.lv_ICDeltParngCust = [deltList1, deltList2, deltList3, deltList4]

        // 데이터 가공(UI 구조에 맞춰)
        this.fn_SetDelParngCustList()
      },

      /******************************************************************************
      * Function명 : fn_SetDelParngCustList
      * 설명       : 삭제예정고객 화면 구조에 맞춰 데이터 가공한다.
      ******************************************************************************/
      fn_SetDelParngCustList () {
        const lv_Vm = this

        this.lv_DeltCustList = [
          {evtTypNm: 'D-day', totCnt: 0, custNmList: ''},
          {evtTypNm: 'D-1일', totCnt: 0, custNmList: ''},
          {evtTypNm: 'D-2일', totCnt: 0, custNmList: ''},
          {evtTypNm: 'D-3일', totCnt: 0, custNmList: ''}]
        
        this.lv_ICDeltParngCust.forEach((item, idx) => {
          let custList = []

          lv_Vm.lv_DeltCustList[idx].totCnt = item.length // 고객수
          if (item.length > 0) {
            for (let i=0; i<item.length; i++) {
              custList.push(item[i].custNm)
            }

            // 고객 리스트
            lv_Vm.lv_DeltCustList[idx].custNmList = custList.join(', ')
          }

        })

        lv_Vm.deltCustData = lv_Vm.lv_DeltCustList.filter(list => list.custNmList.length > 0)

        this.lv_DeltCustList = [{evtTypNm: '필컨만료', totCnt: 99}, ...this.lv_DeltCustList]
      },


      /******************************************************************************
      * Function명  : fn_LoadImg
      * 설명        : 이미지 조회
      ******************************************************************************/
      fn_LoadImg(item) {
        let sDate = moment(item.startDate).format('YYYYMMDD') // 일정 시작일
        let d3Month = moment(new Date().setMonth(new Date().getMonth() - 3)).format('YYYYMMDD')

        console.log('Show promotion image!', item)
        console.log('Show Date!!', sDate, d3Month)

        if (this.isFc && this.getStore('userInfo').getters.getUserInfo.userId !== item.regntNo) {
          if (sDate <= d3Month) {
            let props = {msg: '3개월 내 차트만 열람 가능합니다.'}
            this.$cmUtil.fn_OpenAlert(this, props)
            return
          }
        }
        this.lv_ImgList = []

        const lv_Vm = this
        const auth = 'S'
        const trnstId = 'txTSSAP20S6'

        let pParams = {
          fcSchdlScCd: '3',
          fcSchdlSno: item.fcSchdlSno
        }

        this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          console.log('Promotion image load!!', res)

          if (!_.isEmpty(res.body)) {
            if (!lv_Vm.$bizUtil.isEmpty(res.body.imgFstData)) {
              lv_Vm.lv_ImgList.push(res.body.imgFstData)
            }

            if (!lv_Vm.$bizUtil.isEmpty(res.body.imgScndData)) {
              lv_Vm.lv_ImgList.push(res.body.imgScndData)
            }

            if (res.body.imgFstData === '0') { // 1년지난 이미지 조회
              this.$addSnackbar('이미지 보관일이 지났습니다.')
            } else if (res.body.imgFstData === '-1') { // 파일이 존재하지 않을 경우
              this.$addSnackbar('이미지 조회를 실패하였습니다.')
            } else {

              lv_Vm.$refs.bottom_sheet_1.open()
            }
          } else {
            this.$addSnackbar('이미지 조회를 실패하였습니다.')
          }
        })
        .catch(err => {
          this.$addSnackbar('시스템 오류가 발생하였습니다.')
        })
        
      },

      /******************************************************************************
      * Function명  : fn_CloseSheet1
      * 설명        : 차트 팝업 닫기
      ******************************************************************************/
      fn_CloseSheet1() {
        this.lv_SelectDay = {} // 초기화
        this.$refs.bottom_sheet_1.close()
      },
      /******************************************************************************
      * Function명  : fn_CloseSheet2
      * 설명        : 차트 팝업 닫기
      ******************************************************************************/
      fn_CloseSheet2() {
        this.$refs.bottom_sheet_2.close()
      },

      /******************************************************************************
      * Function명  : fn_ZoomViewer
      * 설명        : 이미지 줌 플러그인 호출
      ******************************************************************************/
      fn_ZoomViewer (idx) {
        let imgData = this.lv_ImgList[idx]

        window.fdpbridge.exec('ZoomViewerPlugin', {
          img: imgData
        }, () => {
          console.log(':: ZoomPlugin success')
        }, (error) => {
          console.log(':: ZoomPlugin fail')
          window.vue.error(error)
        });

      },

      /******************************************************************************
      * Function명  : fn_ImgClick
      * 설명        : 이미지 클릭 이벤트
      ******************************************************************************/
      fn_ImgClick(idx) {
        console.log('fn_ImgClick', idx)
        this.fn_ZoomViewer(idx)
      },

      /******************************************************************************
      * Function명  : fn_FcInfoListClick
      * 설명        : FC별현황 클릭 이벤트
      ******************************************************************************/
      fn_FcInfoListClick() {
        console.log('FC별현황 Click!!!')

        this.fn_SelFcInfoList()
        this.$refs.bottom_sheet_2.open()
        
        if(this.$refs.tableAreaPop.clientHeight < this.$refs.tableAreaPop.scrollHeight){
          this.isShowChevron = true
          this.isShowChevronDown = true
        }
        // Y축 스크롤 없으면
        else if(this.$refs.tableAreaPop.clientHeight >= this.$refs.tableAreaPop.scrollHeight){
          this.isShowChevron = true
          this.isShowChevronDown = true
        }
      },

      /******************************************************************************
      * Function명  : fn_SelFcInfoList
      * 설명        : FC별현황 조회
      ******************************************************************************/
      fn_SelFcInfoList() {
        const lv_Vm = this

        let today = moment(new Date()).format('YYYYMMDD')
        let pParams = {
          cnsltNo: this.getStore('userInfo').getters.getUserInfo.userId,
          stndYmd: today
        }
        const trnstId = 'txTSSAP11S1'
        const auth = 'S'

        this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          if (!_.isEmpty(res.body) && !_.isEmpty(res.body.fcActInfoList)) {
            let fcActInfoList = res.body.fcActInfoList
            lv_Vm.lv_FcActInfoListSVO = fcActInfoList

            fcActInfoList.forEach(fcActInfoItem => {
              fcActInfoItem.acvmtLabel = ' ' // defualt 빈값

              if (!_.isEmpty(fcActInfoItem.gldnActVO) && !_.isEmpty(fcActInfoItem.gldnActVO.iCGldnrActWeklySmupVO)) {
                let curItem = fcActInfoItem.gldnActVO.iCGldnrActWeklySmupVO.filter(item => item.curYn === 'Y')[0]

                if (!_.isEmpty(curItem)) {
                  // GldnrActWeklySmupVO.acvmtYn (1=달성, 2=미달성, 3=진행중, 4=예정, 5=미진행)
                  fcActInfoItem.acvmtLabel = curItem.acvmtYn === '1' ? '달성' : curItem.acvmtYn === '2' ? '미달성' : curItem.acvmtYn === '3' ? '진행중' : curItem.acvmtYn === '4' ? '예정' : curItem.acvmtYn === '5' ? '미진행' : '-'
                }
              }
            })
          }
        })
        .catch(err => {
          window.vue.error(err)
        })

      },

      fn_checkScroll() {
        if(this.$refs.tableAreaPop.scrollLeft > 10 || this.$refs.tableAreaPop.scrollTop > 10 ) { 
          this.isShowChevron = false
          this.isShowChevronDown = false
        }
        let scrollHeight = this.$refs.tableAreaPop.scrollHeight - 10
        let scrollTop = this.$refs.tableAreaPop.scrollTop
        let offsetHeight = this.$refs.tableAreaPop.offsetHeight

        if(offsetHeight + scrollTop  > scrollHeight){
          // 스크롤 바닥칠때 호출함. 필요시 사용하세요
          // this.fn_LoadingData()
        }
      },
      fn_scrollToRight() {
        this.isShowChevron = false
        this.isShowChevronDown = false

        const tableArea = this.$refs.tableAreaPop
        const scrollAmount = 200
        const scrollDuration = 1000
        let startingPosition = tableArea.scrollLeft
        let targetPosition = startingPosition + scrollAmount
        this.animateScroll(tableArea, startingPosition, targetPosition, scrollDuration, 'horizontal')
      },

      fn_scrollToDown() {
        this.isShowChevron = false
        this.isShowChevronDown = false 

        const tableArea = this.$refs.tableAreaPop
        const scrollAmount = 200
        const scrollDuration = 1000
        const startingPosition = tableArea.scrollTop
        const targetPosition = startingPosition + scrollAmount
        this.animateScroll(tableArea, startingPosition, targetPosition, scrollDuration, 'vertical')
      },

      fn_LoadingData(){
        // 통신중 체크해서 중복호출 막아야함
        // if (this.srdvlVO.length !== 0 && !this.loadingFlag) {
      	  console.log("@@@스크롤 조회@@@")
        // }
      },

      /******************************************************************************
      * Function명  : fn_SchdlSort
      * 설명        : 시간순 정렬
      ******************************************************************************/
      fn_SchdlSort() {
        // 데이터 정렬 
          let period = []
          let oneDay = []

          // 기간일정 구분
          for(let i=0; i<this.SchdList.length; i ++) {
            if(this.SchdList[i].fcSchdlStrYmd != this.SchdList[i].fcSchdlEndYmd && this.SchdList[i].fcSchdlStrYmd != today) {
              period.push(this.SchdList[i])
            } else {
              oneDay.push(this.SchdList[i])
            }
          }

          // 기간일정 정렬
          period.sort((a,b) => {
            if(a.fcSchdlStrYmd !== b.fcSchdlStrYmd) {
              return a.fcSchdlStrYmd.localeCompare(b.fcSchdlStrYmd)
            } else if(!this.$bizUtil.isEmpty(a.compareTime) && !this.$bizUtil.isEmpty(b.compareTime)) {
              return a.compareTime - b.compareTime
            }
          })

          // 기간일정 중, 오늘이 마지막날인 경우
          for(let i=0;i<period.length;i++) {
            if(period[i].startTime !== '[종일]' && period[i].fcSchdlEndYmd === today) {
              let hour24  = parseInt(period[i].endTime.slice(0,2))
              let minutes = parseInt(period[i].endTime.slice(2))

              let hour12  = hour24 % 12 || 12

              if(hour12.toString().length === 1) {
                hour12 = "0" + hour12
              }
              if(minutes.toString().length === 1) {
                minutes = "0" + minutes
              }

              period[i].startTime = "[-" + hour12 + ":" + minutes + "]"

              if(period[i].endTime.substr(0,2) > 11) {
                period[i].afternoonYn = '오후'
              } else {
                period[i].afternoonYn = '오전'
              }
            }
          }

          // 당일일정 정렬
          oneDay.sort((a,b) => {
            if(a.fcSchdlStrYmd !== b.fcSchdlStrYmd) {
              return a.fcSchdlStrYmd.localeCompare(b.fcSchdlStrYmd)
            } else if(!this.$bizUtil.isEmpty(a.compareTime) && !this.$bizUtil.isEmpty(b.compareTime)) {
              return a.compareTime - b.compareTime
            }
          })

          this.SchdList = []
          this.SchdList = period.concat(oneDay)
          
          console.log("데이터 정렬", this.SchdList)
      }
  } // end methods
}
</script>
<style scoped>
</style>
